<template>
  <div class="pt-5 pb-5 w-full">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'PerformanceSettings',
            query: { activeTab: 'Appraisal & Moderation' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 300px; margin-top: 0.1rem">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          {{
            $route.params.id === "create"
              ? "New Moderation Structure"
              : "Edit Moderation Structure"
          }}
        </h1>
      </div>
      <div class="w-7/12" style="margin-top: 1.2rem">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="px-3">
      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <card class="mt-6 p-5">
          <div class="flex font-bold text-darkPurple text-lg flex-grow">
            Reference Template
          </div>
          <div class="w-1/3 flex flex-column mt-3">
            <c-select
              placeholder="--Select from existing template--"
              :options="templateOptions"
              variant="w-full"
              class="w-full"
              :disables="$route.params.id === 'create' ? false : true"
              v-model="templateSelected"
              :rules="['required']"
            />
          </div>
        </card>

        <card class="mt-6 p-5">
          <div class="flex font-bold text-darkPurple text-lg flex-grow">
            Appraisees
          </div>
          <div class="w-full border-dashed border border-gray-200 my-6 p-3">
            <div class="w-full flex my-4">
              <span
                class="font-black text-xs uppercase text-romanSilver w-2/12"
              >
                ADD APPRAISEES
              </span>
              <div
                class="border border-gray-200 border-solid w-full mt-2 -ml-8"
                style="height: 0px; border-width: 0.5px"
              />
              <button class="flex ml-8" @click="addModeration = true">
                <Icon size="xsm" icon-name="icon-plus" />
                <p class="ml-3 text-flame text-base font-semibold">Add</p>
              </button>
            </div>
          </div>
        </card>

        <card class="mt-1" style="padding: 20px">
          <div
            class="flex w-full flex-col h-auto"
            style="border-bottom: 1px solid #878e99"
          >
            <div
              class="flex font-bold text-darkPurple"
              style="font-size: 18px; flex-grow: 1"
            >
              Moderators
              <icon icon-name="icon-info" class="ml-3"
              v-tooltip.bottom-start="
              `Choose the moderators for selected employees. <br /> These
                    moderators can be selected based on job level or
                    designations. <br /> The appraisee’s line manager by default, is a
                    moderator.`
              " />
            </div>
            <div
              class="flex w-full my-5"
              v-for="(moderation, index) in divide
                ? selectedModerators
                : selectedModerators.slice(0, 2)"
              :key="index"
            >
              <div class="w-4/12">
                <c-select
                  placeholder="Automatic"
                  :options="jobLevels"
                  label="Line Manager"
                  variant="w-full"
                  disabled
                  v-if="moderation.levelId === 'Line Manager'"
                />
                <c-select
                  placeholder="--Select Job Level--"
                  label="Chief Moderator"
                  variant="w-full"
                  :options="jobLevels"
                  v-model="moderation.levelId"
                  @input="moderatorSelect('2', moderation.levelId)"
                  :rules="['required']"
                  v-else
                />
              </div>
              <div class="w-2/12 mx-7">
                <c-text
                  :placeholder="'Weight'"
                  label="Relative Weighting (%)"
                  variant="w-full"
                  type="number"
                  v-model="moderation.weight"
                  :rules="weightRules"
                />
              </div>
            </div>
          </div>
          <div class="flex w-full flex-col h-auto">
            <div
              class="flex font-bold text-darkPurple mt-5"
              style="font-size: 18px; flex-grow: 1"
            >
              Other Moderators
            </div>
            <div
              class="flex flex-col w-full my-5"
              v-for="(moderation, index) in divide
                ? selectedModerators
                : selectedModerators.slice(2, 1000)"
              :key="index"
            >
              <div class="flex">
                <div class="w-4/12">
                  <c-select
                    placeholder="--Select Job Level--"
                    label="Moderator"
                    variant="w-full sel"
                    :options="jobLevels"
                    :rules="['required']"
                    v-model="moderation.levelId"
                    @input="moderatorOthersSelect(index, moderation.levelId)"
                  />
                </div>
                <div class="w-2/12 mx-7">
                  <c-text
                    :placeholder="'Weight'"
                    label="Relative Weighting (%)"
                    variant="w-full"
                    type="number"
                    v-model="moderation.weight"
                    :rules="weightRules"
                  />
                </div>

                <div
                  class="flex cursor-pointer flex-col justify-center pt-7"
                  style="height: 65px"
                  @click="deleteModerator(index + 2)"
                >
                  <icon icon-name="icon-trash" class="mt-1" size="xs" />
                </div>
              </div>
            </div>

            <div class="w-full flex">
              <button class="flex flex-grow mt-5" @click="addModerators()">
                <Icon size="xsm" icon-name="icon-plus" />
                <p class="ml-3 text-flame text-base font-semibold">
                  Add Other Moderators
                </p>
              </button>

              <button
                class="flex mt-5 text-flame"
                @click="undoModerationSelection()"
              >
                <Icon size="xms" icon-name="undo" class="mt-1" />
                <p class="ml-3 text-flame text-base font-semibold">
                  Undo Moderation Selections
                </p>
              </button>
            </div>
          </div>
        </card>
        <card class="mt-3" style="padding: 20px">
          <div class="flex w-full flex-col h-auto">
            <div
              class="flex font-bold text-darkPurple"
              style="font-size: 18px; flex-grow: 1"
            >
              Moderation Sequence
              <span
                @mouseover="showSequence = true"
                @mouseleave="showSequence = false"
                class="-mt-2 cursor-pointer"
              >
                <icon icon-name="icon-info" class="ml-3 mt-4" />
                <ToolTip v-if="showSequence" width="298px" left="302px">
                  <template v-slot:header>
                    <p class="font-12 uppercase font-semibold">
                      Moderation Sequence
                    </p>
                  </template>
                  <p style="font-size: 14px">
                    The moderation sequence is choosen based on the moderators
                    selected above. Choose who starts the moderation and who
                    will be the final moderator. The sequence number depends on
                    how many moderators have been chosen
                  </p>
                </ToolTip>
              </span>
            </div>
            <div class="flex w-full flex-wrap my-5">
              <div
                class="flex w-auto mb-6"
                v-for="(s, index) in moderatorSequence"
                :key="index"
              >
                <div class="w-52 flex" v-if="s.levelId === 'Line Manager'">
                  <c-select
                    placeholder="Automatic"
                    class="w-full"
                    :options="selectedModeratorsOpt"
                    :label="s.label"
                    variant="w-full"
                    disabled
                  />
                </div>
                <div class="w-52 flex" v-else>
                  <c-select
                    placeholder="Select"
                    class="w-full"
                    :label="s.label"
                    :options="selectedModeratorsOpt"
                    variant="w-full"
                    :rules="['required']"
                    v-model="s.levelId"
                  />
                </div>
                <div
                  class="ml-6 mr-6 h-24 flex flex-col justify-center"
                  v-if="index < moderatorSequence.length - 1"
                >
                  <icon icon-name="icon-arrow-right" size="xms" />
                </div>
              </div>
            </div>
          </div>
        </card>
        <div class="pl-3 mt-6 flex flex-row">
          <div class="flex flex-grow">
            <Button
              class="bg-dynamicBackBtn text-white"
              width="126px"
              @click="handleSubmit(editModerationStructure)"
            >
              Save
            </Button>
            <Button
              class="text-darkPurple ml-4"
              width="126px"
              @click="
                $router.push({
                  name: 'PerformanceSettings',
                  query: { activeTab: 'Appraisal' },
                })
              "
            >
              Cancel
            </Button>
          </div>
          <div class="flex justify-end">
            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
              width="8rem"
              @click="editModerationStructure('draft')"
              v-if="
                moderationPayload.status === 'draft' &&
                moderationPayload.templateId !== ''
              "
            >
              {{
                $route.params.id !== "create" ? "Update Draft" : "Save Draft"
              }}
            </Button>
          </div>
        </div>
      </ValidationObserver>
    </div>

    <RightSideBar
      v-if="addModeration"
      @submit="submitApprasees"
      @close="closeSideBar"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Add Appraisees</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple my-5 text-base">
          Add appraisees from specified functions, job level or employee
          designations
        </p>
        <div class="w-full flex flex-col my-5">
          <div class="flex">
            <span class="text-sm flex flex-grow"> Locations </span>
            <checkbox
              checkbox-size="height:16px; width:16px; margin-top: 3px;"
              label="All Locations"
              v-model="applyToAllLocations"
            />
          </div>
          <c-select
            placeholder="--Select Location(s)--"
            :options="locations"
            variant="w-full select"
            v-model="selectedLocation"
            :disabled="applyToAllLocations"
          />
        </div>
        <div class="w-12/12 flex flex-col mb-5 -mt-3">
          <div>
            <div class="flex mt-3">
              <span class="text-sm flex flex-grow"> Functions </span>
              <checkbox
                checkbox-size="height:16px; width:16px; margin-top: 3px;"
                label="All Functions"
                v-model="applyToAllFunctions"
              />
            </div>
            <c-select
              placeholder="--Select Function(s)--"
              :options="functions"
              variant="w-full select"
              v-model="selectedFunction"
              :disabled="applyToAllFunctions"
            />
          </div>

          <div class="mt-5">
            <div class="flex">
              <span class="text-sm flex flex-grow"> Job Level </span>
              <checkbox
                checkbox-size="height:16px; width:16px; margin-top: 3px;"
                label="All Job Levels"
                v-model="applyToAllLevels"
              />
            </div>
            <c-select
              placeholder="--Select Job Level(s)--"
              :options="jobLevels"
              variant="w-full select"
              v-model="selectedLevel"
              :disabled="applyToAllLevels"
            />
          </div>

          <div class="mt-5">
            <div class="flex">
              <span class="text-sm flex flex-grow"> Designation </span>
              <checkbox
                checkbox-size="height:16px; width:16px; margin-top: 3px;"
                label="All Designations"
                v-model="applyToAllDesignations"
                :disabled="disableCheckBox"
              />
            </div>
            <c-select
              placeholder="--Select Designation(s)--"
              :options="designations"
              variant="w-full select"
              v-model="selectedDesignation"
              :disabled="applyToAllDesignations"
            />
          </div>
        </div>
      </template>
      <div class="flex mt-5 mb-5">
        <span class="w-10/12 text-flame text-base"> Selected Options </span>
        <span
          class="text-romanSilver text-xs uppercase font-black cursor-pointer"
          @click="clearSelected"
        >
          Clear All
        </span>
      </div>
      <p class="text-darkPurple text-sm mb-2">Locations</p>
      <div
        class="flex my-2 p-3 flex-wrap rounded"
        style="border: 1px dashed #878e99"
      >
        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="selectedLocationTags.length === 0 && !applyToAllLocations"
        >
          None Specified
        </div>

        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="applyToAllLocations"
        >
          All
        </div>

        <div
          class="w-full flex flex-wrap"
          v-if="selectedLocationTags.length > 0 && !applyToAllLocations"
        >
          <MultiSelectTag
            bg-color="#F7F7FF"
            size="xms"
            v-for="(location, index) in selectedLocationTags"
            :key="index"
            @close="deleteSelectedLocation(location.id)"
          >
            <div
              class="text-jet flex flex-grow"
              style="font-size: 14px; margin-right: 10px"
            >
              {{ location.name }}
            </div>
          </MultiSelectTag>
        </div>
      </div>

      <p class="text-darkPurple text-sm mb-2 mt-4">Functions</p>
      <div
        class="flex my-2 p-3 flex-wrap rounded"
        style="border: 1px dashed #878e99"
      >
        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="selectedFunctionTags.length === 0 && !applyToAllFunctions"
        >
          None Specified
        </div>

        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="applyToAllFunctions"
        >
          All
        </div>

        <div
          class="w-full flex flex-wrap"
          v-else-if="selectedFunctionTags.length > 0 && !applyToAllFunctions"
        >
          <MultiSelectTag
            bg-color="#F7F7FF"
            size="xms"
            v-for="(func, index) in selectedFunctionTags"
            :key="index"
            @close="deleteSelectedFunction(func.id)"
          >
            <div
              class="text-jet flex flex-grow"
              style="font-size: 14px; margin-right: 10px"
            >
              {{ func.name }}
            </div>
          </MultiSelectTag>
        </div>
      </div>

      <p class="text-darkPurple text-sm mb-2 mt-4">Job Levels</p>
      <div
        class="flex my-2 p-3 flex-wrap rounded"
        style="border: 1px dashed #878e99"
      >
        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="selectedLevelTags.length === 0 && !applyToAllLevels"
        >
          None Specified
        </div>

        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="applyToAllLevels"
        >
          All
        </div>

        <div
          class="w-full flex flex-wrap"
          v-if="selectedLevelTags.length > 0 && !applyToAllLevels"
        >
          <MultiSelectTag
            bg-color="#F7F7FF"
            size="xms"
            v-for="(level, index) in selectedLevelTags"
            :key="index"
            @close="deleteSelectedLevel(level.id)"
          >
            <div
              class="text-jet flex flex-grow"
              style="font-size: 14px; margin-right: 10px"
            >
              {{ level.name }}
            </div>
          </MultiSelectTag>
        </div>
      </div>

      <p class="text-darkPurple text-sm mb-2 mt-4">Designations</p>
      <div
        class="flex my-2 p-3 flex-wrap rounded"
        style="border: 1px dashed #878e99"
      >
        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="selectedDesignationTags.length === 0 && !applyToAllDesignations"
        >
          None Specified
        </div>

        <div
          class="text-xs font-bold uppercase text-romanSilver"
          v-if="applyToAllDesignations"
        >
          All
        </div>

        <div
          class="w-full flex flex-wrap"
          v-if="selectedDesignationTags.length > 0 && !applyToAllDesignations"
        >
          <MultiSelectTag
            bg-color="#F7F7FF"
            size="xms"
            v-for="(designation, index) in selectedDesignationTags"
            :key="index"
            @close="deleteSelectedDesignation(designation.id)"
          >
            <div
              class="text-jet flex flex-grow"
              style="font-size: 14px; margin-right: 10px"
            >
              {{ designation.name }}
            </div>
          </MultiSelectTag>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Checkbox from "@/components/Checkbox";
import ToolTip from "@/components/ToolTip";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import MultiSelectTag from "@/components/MultiSelectTag";

export default {
  name: "ModerationStructure",
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    CSelect,
    CText,
    Checkbox,
    Card,
    Icon,
    ToolTip,
    Button,
    RightSideBar,
    MultiSelectTag,
  },

  data() {
    return {
      templateOptions: [],
      appraiseesItems: true,
      showModerators: false,
      showSequence: false,
      addModeration: false,
      divide: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings",
        },
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text:
            this.$route.params.id === "create"
              ? "New Moderation Structure"
              : "Edit Moderation Structure",
          id: "Edit Moderation Structure",
        },
      ],
      levels: [{ tags: "Level 1" }, { tags: "Level 2" }, { tags: "Level 2B" }],
      selectedModeratorLevel: [],
      applyToAllLocations: false,
      applyToAllLevels: false,
      applyToAllFunctions: false,
      applyToAllDesignations: false,
      disableCheckBox: true,
      templateSelected: "",
      selectedLocation: "",
      selectedFunction: "",
      selectedLevel: "",
      selectedDesignation: "",
      selectedLocationTags: [],
      selectedFunctionTags: [],
      selectedLevelTags: [],
      selectedDesignationTags: [],
      selectedOtherModeratorLevel: [],
      locations: [],
      functions: [],
      jobLevels: [],
      designations: [],
      allDesignations: [],
      selectedModerators: [],
      selectedModeratorsOpt: [],
      moderatorSequence: [
        {
          levelId: "Line Manager",
          sequence: 0,
          label: "Line Manager",
          weight: null,
        },
        {
          levelId: "",
          sequence: 1,
          label: `Line Moderator 1`,
          weight: null,
        },
      ],
      moderationPayload: {
        orgId: null,
        templateId: null,
        appliedTo: "specific_employees",
        status: "draft",
        appraiseeLocationId: [],
        appraiseeFunctionId: [],
        appraiseeLevelId: [],
        appraiseeDesignationId: [],
        automaticModerator: "line_manager",
        automaticModeratorWeight: null,
        moderators: [],
      },
      sequenceList: [
        "Chief Moderator",
        "Third Moderator",
        "Fourth Moderator",
        "Fifth Moderator",
        "Sixth Moderator",
        "Seventh Moderator",
        "Eight Moderator",
        "Ninth Moderator",
        "Tenth Moderator",
      ],
      weightRules: [
        "required",
        {
          name: "value",
          rule: (w) => this.validateWeight(w),
        },
      ],
    };
  },

  watch: {
    applyToAllLocations(value) {
      if (value) {
        this.locations.filter((location) => {
          this.moderationPayload.appraiseeLocationId.push(location.id);
          return {};
        });
      } else {
        this.moderationPayload.appraiseeLocationId = [];
      }
    },

    applyToAllFunctions(value) {
      if (value) {
        this.functions.filter((func) => {
          this.moderationPayload.appraiseeFunctionId.push(func.id);
          return {};
        });

        this.getDesignations("");
        this.disableCheckBox = false;
      } else {
        this.designations = [];
        this.moderationPayload.appraiseeFunctionId = [];
      }
    },

    applyToAllLevels(value) {
      if (value) {
        this.jobLevels.filter((level) => {
          this.moderationPayload.appraiseeLevelId.push(level.id);
          return {};
        });
      } else {
        this.moderationPayload.appraiseeLevelId = [];
      }
    },

    applyToAllDesignations(value) {
      if (value) {
        this.designations.filter((designation) => {
          this.moderationPayload.appraiseeDesignationId.push(designation.id);
          return {};
        });

        this.getDesignations();
      } else {
        this.designations = [];
        this.moderationPayload.appraiseeFunctionId = [];
      }
    },

    templateSelected(val) {
      this.moderationPayload.templateId = val;
    },

    selectedLocation() {
      this.locations.filter((location) => {
        if (location.id === this.selectedLocation) {
          this.selectedLocationTags.push({
            id: location.id,
            name: location.name,
          });
        }
        return {};
      });

      const disableIndex = this.locations
        .map((item) => item.id)
        .indexOf(this.selectedLocation);

      document
        .querySelectorAll(".select")[0]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    selectedFunction() {
      this.functions.filter((f) => {
        if (f.id === this.selectedFunction) {
          this.selectedFunctionTags.push({
            id: f.id,
            name: f.name,
          });
        }
        return {};
      });

      this.disableCheckBox = false;

      if (this.selectedFunction !== "") {
        this.getDesignations(this.selectedFunction);
      }

      const disableIndex = this.functions
        .map((item) => item.id)
        .indexOf(this.selectedFunction);

      document
        .querySelectorAll(".select")[1]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    selectedLevel() {
      this.jobLevels.filter((level) => {
        if (level.id === this.selectedLevel) {
          this.selectedLevelTags.push({
            id: level.id,
            name: level.name,
          });
        }
        return {};
      });

      const disableIndex = this.jobLevels
        .map((item) => item.id)
        .indexOf(this.selectedLevel);

      document
        .querySelectorAll(".select")[2]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    selectedDesignation() {
      this.designations.filter((designation) => {
        if (designation.id === this.selectedDesignation) {
          this.selectedDesignationTags.push({
            id: designation.id,
            name: designation.name,
          });
        }
        return {};
      });

      const disableIndex = this.designations
        .map((item) => item.id)
        .indexOf(this.selectedDesignation);

      document
        .querySelectorAll(".select")[3]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },
  },

  methods: {
    deleteModerator(index) {
      this.selectedModerators.splice(index, 1);
      this.moderatorSequence.splice(index, 1);
    },

    undoModerationSelection() {
      this.selectedModeratorLevel = [];
      const { length } = document.querySelectorAll(".sel");

      for (let i = 0; i < length; i++) {
        this.selectedModerators.splice(i + 1, 1);
        this.moderatorSequence.splice(i + 1, 1);
      }

      this.jobLevels.filter((level) => {
        const disableIndex = this.jobLevels
          .map((item) => item.id)
          .indexOf(level.id);
        document
          .querySelectorAll("select")[2]
          .options[disableIndex + 1].removeAttribute("disabled");

        return {};
      });

      this.selectedModerators.forEach((moderator) => {
        if (moderator.levelId !== "Line Manager") {
          // eslint-disable-next-line no-param-reassign
          moderator.levelId = "";
        }
        // eslint-disable-next-line no-param-reassign
        moderator.weight = "";
      });

      this.moderatorSequence.forEach((moderator) => {
        if (moderator.levelId !== "Line Manager") {
          // eslint-disable-next-line no-param-reassign
          moderator.levelId = "";
        }
      });
    },

    moderatorSelect(index, id) {
      this.selectedModeratorLevel = [];

      const { length } = document.querySelectorAll(".sel");

      for (let i = 0; i < length; i++) {
        if (document.querySelectorAll(".sel")[i].value !== "") {
          this.selectedModeratorLevel.push(
            document.querySelectorAll(".sel")[i].value
          );
        }
      }

      this.selectedModeratorLevel.push(id);

      const disableIndex = this.jobLevels.map((item) => item.id).indexOf(id);

      document
        .querySelectorAll("select")
        [index].options[disableIndex + 1].setAttribute("disabled", true);

      for (let i = 0; i < length; i++) {
        document
          .querySelectorAll(".sel")
          [i].options[disableIndex + 1].setAttribute("disabled", true);
      }

      // Remove other selected Options
      this.jobLevels.filter((level) => {
        const includes = this.selectedModeratorLevel.includes(level.id);
        if (!includes) {
          const optionsIndex = this.jobLevels
            .map((item) => item.id)
            .indexOf(level.id);

          document
            .querySelectorAll("select")[2]
            .options[optionsIndex + 1].removeAttribute("disabled");

          for (let i = 0; i < length; i++) {
            document
              .querySelectorAll(".sel")
              [i].options[optionsIndex + 1].removeAttribute("disabled");
          }
        }
        return {};
      });

      this.moderatorSequence.forEach((sequence) => {
        if (sequence.sequence === 1) {
          // eslint-disable-next-line no-param-reassign
          sequence.levelId = id;
        }
      });
    },

    moderatorOthersSelect(index, id) {
      this.selectedModeratorLevel = [];
      const { length } = document.querySelectorAll(".sel");

      for (let i = 0; i < length; i++) {
        this.selectedModeratorLevel.push(
          document.querySelectorAll(".sel")[i].value
        );
      }

      this.selectedModeratorLevel.push(
        document.querySelectorAll("select")[2].value
      );

      const disableIndex = this.jobLevels.map((item) => item.id).indexOf(id);

      document
        .querySelectorAll("select")[2]
        .options[disableIndex + 1].setAttribute("disabled", true);

      for (let i = 0; i < length; i++) {
        document
          .querySelectorAll(".sel")
          [i].options[disableIndex + 1].setAttribute("disabled", true);
      }

      // Remove other selected Options
      this.jobLevels.filter((level) => {
        const includes = this.selectedModeratorLevel.includes(level.id);
        if (!includes) {
          const optionsIndex = this.jobLevels
            .map((item) => item.id)
            .indexOf(level.id);

          document
            .querySelectorAll("select")[2]
            .options[optionsIndex + 1].removeAttribute("disabled");

          for (let i = 0; i < length; i++) {
            document
              .querySelectorAll(".sel")
              [i].options[optionsIndex + 1].removeAttribute("disabled");
          }
        }
        return {};
      });

      this.moderatorSequence.forEach((sequence) => {
        if (sequence.sequence === index + 2) {
          // eslint-disable-next-line no-param-reassign
          sequence.levelId = id;
        }
      });
    },

    openModerationBar() {
      this.moderationPayload.appraiseeLocationId.map((location) => {
        this.timer = setTimeout(() => {
          this.selectedLocation = location;
        }, 1000);
        return {};
      });

      this.moderationPayload.appraiseeFunctionId.map((func) => {
        this.timer = setTimeout(() => {
          this.selectedFunction = func;
        }, 1000);
        return {};
      });

      this.moderationPayload.appraiseeLevelId.map((level) => {
        this.timer = setTimeout(() => {
          this.selectedLevel = level;
        }, 1000);
        return {};
      });

      this.moderationPayload.appraiseeDesignationId.map((designation) => {
        this.timer = setTimeout(() => {
          this.selectedDesignation = designation;
        }, 2000);
        return {};
      });
    },

    validateWeight(w) {
      if (w < 0) {
        return `Value must be greater than or equal to 0`;
      }
      if (w > 0) {
        return this.watchWeightMax();
      }

      return true;
    },

    watchWeightMax(action) {
      let sum = 0;
      this.selectedModerators.map((moderator) => {
        const moderatorConst = moderator;

        moderatorConst.weight = Number(moderator.weight);

        sum += moderatorConst.weight;
        return {};
      });

      if (sum > 100) {
        return "Total weight must not be greater than 100%";
      }

      if (action) {
        if (sum === 100) {
          return false;
        }
      }
      return true;
    },

    closeSideBar() {
      this.addModeration = false;
    },

    deleteSelectedFunction(id) {
      const optionsIndex = this.functions.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedFunctionTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedFunctionTags.splice(selectedIndex, 1);
      document
        .querySelectorAll(".select")[1]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedFunctionTags.length === 0) {
        this.selectedFunction = "";
        this.designations = [];
      } else {
        this.allDesignations.filter((designation) => {
          if (id === designation.functionId) {
            this.deleteSelectedDesignation(designation.id);
          }
          return {};
        });

        this.designations = [];
        this.selectedFunctionTags.forEach((tag) => {
          this.getDesignations(tag.id);
        });
      }
    },

    deleteSelectedDesignation(id) {
      const optionsIndex = this.designations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedDesignationTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedDesignationTags.splice(selectedIndex, 1);
      document
        .querySelectorAll(".select")[3]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedDesignationTags.length === 0) {
        this.selectedDesignation = "";
      }
    },

    deleteSelectedLevel(id) {
      const optionsIndex = this.jobLevels.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedLevelTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedLevelTags.splice(selectedIndex, 1);
      document
        .querySelectorAll(".select")[2]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedLevelTags.length === 0) {
        this.selectedLevel = "";
      }
    },

    deleteSelectedLocation(id) {
      const optionsIndex = this.locations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedLocationTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedLocationTags.splice(selectedIndex, 1);
      document
        .querySelectorAll(".select")[0]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedLocationTags.length === 0) {
        this.selectedLocation = "";
      }
    },

    addModerators() {
      const weightLess = this.watchWeightMax("action");

      if (weightLess) {
        this.selectedModerators.push({
          levelId: "",
          weight: null,
          moderator: "",
        });

        this.moderatorSequence = this.selectedModerators.map(
          (moderation, index) => ({
            levelId:
              moderation.levelId === "Line Manager"
                ? `Line Manager`
                : moderation.levelId,
            sequence: index,
            label:
              moderation.levelId === "Line Manager"
                ? `Line Manager`
                : `Line Moderator ${index}`,
            weight: moderation.weight,
            moderator: "",
          })
        );
      } else {
        this.$toasted.error("Total weight of 100% reached", { duration: 5000 });
      }

      setTimeout(() => {
        const { length } = document.querySelectorAll(".sel");

        for (let i = 0; i < length; i++) {
          this.selectedModeratorLevel.map((levelId) => {
            const disableIndex = this.jobLevels
              .map((item) => item.id)
              .indexOf(levelId);
            document
              .querySelectorAll(".sel")
              [i].options[disableIndex + 1].setAttribute("disabled", true);

            return {};
          });
        }
      }, 1000);
    },

    submitApprasees() {
      this.moderationPayload.appraiseeLocationId = [];
      this.moderationPayload.appraiseeLevelId = [];
      this.moderationPayload.appraiseeFunctionId = [];
      this.moderationPayload.appraiseeDesignationId = [];

      if (!this.applyToAllLocations) {
        this.moderationPayload.applyToAllLocations = false;
        this.selectedLocationTags.map((selected) => {
          this.moderationPayload.appraiseeLocationId.push(selected.id);
          return {};
        });
      } else {
        this.moderationPayload.applyToAllLocations = true;
        this.locations.filter((location) => {
          this.moderationPayload.appraiseeLocationId.push(location.id);
          return {};
        });
      }

      if (!this.applyToAllLevels) {
        this.moderationPayload.applyToAllLevels = false;
        this.selectedLevelTags.map((selected) => {
          this.moderationPayload.appraiseeLevelId.push(selected.id);
          return {};
        });
      } else {
        this.moderationPayload.applyToAllLevels = true;
        this.jobLevels.filter((levels) => {
          this.moderationPayload.appraiseeLevelId.push(levels.id);
          return {};
        });
      }

      if (!this.applyToAllFunctions) {
        this.moderationPayload.applyToAllFunctions = false;
        this.selectedFunctionTags.map((selected) => {
          this.moderationPayload.appraiseeFunctionId.push(selected.id);
          return {};
        });
      } else {
        this.moderationPayload.applyToAllFunctions = true;
        this.functions.filter((func) => {
          this.moderationPayload.appraiseeFunctionId.push(func.id);
          return {};
        });
      }

      if (!this.applyToAllDesignations) {
        this.moderationPayload.applyToAllDesignations = false;
        this.selectedDesignationTags.map((selected) => {
          this.moderationPayload.appraiseeDesignationId.push(selected.id);
          return {};
        });
      } else {
        this.moderationPayload.applyToAllDesignations = true;
        this.designations.filter((designation) => {
          this.moderationPayload.appraiseeDesignationId.push(designation.id);
          return {};
        });
      }

      this.$toasted.success("Appraisees added successfully", {
        duration: 5000,
      });

      this.closeSideBar();
    },

    save(status) {
      if (!status) {
        this.moderationPayload.status = "active";
      }

      this.moderatorSequence.forEach((item) => {
        const sequenceData = item;
        this.selectedModerators.filter((moderator) => {
          if (moderator.levelId === item.levelId) {
            sequenceData.weight = moderator.weight;
          }
          return {};
        });
      });

      const sequenceArr = this.moderatorSequence.map(({ label, ...rest }) => ({
        ...rest,
      }));

      this.moderationPayload.moderators = sequenceArr;
      this.moderationPayload.orgId = this.$orgId;

      let sum = 0;
      this.selectedModerators.map((moderator) => {
        const moderatorConst = moderator;

        moderatorConst.weight = Number(moderator.weight);

        sum += moderatorConst.weight;
        return {};
      });

      if (sum < 100 || sum > 100) {
        this.$toasted.error("Total moderator weight must be 100%", {
          duration: 5000,
        });
      } else if (sum === 100) {
        this.moderationPayload.automaticModeratorWeight =
          this.moderationPayload.moderators[0].weight;
        this.moderationPayload.moderators.splice(0, 1);

        this.moderationPayload.moderators.forEach((moderators, index) => {
          // eslint-disable-next-line no-param-reassign
          moderators.moderator = this.sequenceList[index];
        });

        this.$_createModerationStructure(this.moderationPayload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.push({
              name: "PerformanceSettings",
              query: { activeTab: "Appraisal & Moderation" },
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      }
    },

    edit(status) {
      if (!status) {
        this.moderationPayload.status = "active";
      }
      this.moderatorSequence.forEach((item) => {
        const sequenceData = item;
        this.selectedModerators.filter((moderator) => {
          if (moderator.levelId === item.levelId) {
            sequenceData.weight = moderator.weight;
          }
          return {};
        });
      });

      const sequenceArr = this.moderatorSequence.map(({ label, ...rest }) => ({
        ...rest,
      }));

      this.moderationPayload.moderators = sequenceArr;
      this.moderationPayload.orgId = this.$orgId;
      this.moderationPayload.id = this.$route.params.id;

      let sum = 0;
      this.selectedModerators.map((moderator) => {
        const moderatorConst = moderator;

        moderatorConst.weight = Number(moderator.weight);

        sum += moderatorConst.weight;
        return {};
      });

      if (sum < 100 || sum > 100) {
        this.$toasted.error("Total moderator weight must be 100%", {
          duration: 5000,
        });
      } else if (sum === 100) {
        this.moderationPayload.automaticModeratorWeight =
          this.moderationPayload.moderators[0].weight;
        this.moderationPayload.moderators.splice(0, 1);

        this.moderationPayload.moderators.forEach((moderators, index) => {
          // eslint-disable-next-line no-param-reassign
          moderators.moderator = this.sequenceList[index];
        });

        this.$_editModerationStructure(this.moderationPayload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.push({
              name: "PerformanceSettings",
              query: { activeTab: "Appraisal & Moderation" },
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      }
    },

    clearSelected() {
      this.selectedLocation = "";
      this.selectedFunction = "";
      this.selectedLevel = "";
      this.selectedDesignation = "";
      this.selectedLocationTags = [];
      this.selectedFunctionTags = [];
      this.selectedLevelTags = [];
      this.selectedDesignationTags = [];
      this.designations = [];

      this.getLocation();
      this.getJobLevels();
      this.getFunctions();
    },

    getLocation() {
      this.locations = [];
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.forEach((outlet) => {
          this.locations.push({
            id: outlet.id,
            name: outlet.name,
          });
        });
      });
    },

    getJobLevels() {
      this.jobLevels = [];
      this.$_getLevels().then((response) => {
        response.data.levels.forEach((level) => {
          this.jobLevels.push({
            id: level.id,
            name: level.name,
          });
        });
      });
    },

    getFunctions() {
      this.functions = [];
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    getDesignations(id) {
      this.$_getDesignations("").then((response) => {
        if (id !== "") {
          response.data.designations.filter((designation) => {
            if (id === designation.functionId) {
              this.designations.push({
                id: designation.id,
                name: designation.name,
              });
            }
            return {};
          });
        } else {
          this.designations = [];
          response.data.designations.filter((designation) => {
            this.designations.push({
              id: designation.id,
              name: designation.name,
            });
            return {};
          });
        }

        this.allDesignations = response.data.designations;
      });
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate('').then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.status !== "draft") {
            this.templateOptions.push({
              id: template.id,
              name: template.title,
            });
          }
          return {};
        });
      });
    },

    setModeratorsSequence() {
      const selectedOptions = [];
      this.selectedModerators.forEach((moderation) => {
        this.jobLevels.filter((level) => {
          if (level.id === moderation.levelId) {
            selectedOptions.push({
              id: level.id,
              name: level.name,
            });
          }
          return {};
        });
      });

      this.selectedModeratorsOpt = selectedOptions;
    },

    setSequence() {
      this.selectedModerators.push(
        {
          levelId: "Line Manager",
          label: "",
          weight: null,
        },
        {
          levelId: "",
          label: "",
          weight: null,
        }
      );
    },

    displayModerationStructure(id) {
      this.moderatorSequence = [];
      this.$_getOneModerationStructure(id).then((response) => {
        this.templateSelected = response.data.moderation.templateId;
        this.moderationPayload.appraiseeLocationId =
          response.data.moderation.appraiseeLocationId;
        this.moderationPayload.appraiseeFunctionId =
          response.data.moderation.appraiseeFunctionId;
        this.moderationPayload.appraiseeLevelId =
          response.data.moderation.appraiseeLevelId;
        this.moderationPayload.appraiseeDesignationId =
          response.data.moderation.appraiseeDesignationId;
        this.moderationPayload.status = response.data.moderation.status;

        this.moderationPayload.applyToAllDesignations = response.data.moderation.applyToAllDesignations;
        this.moderationPayload.applyToAllFunctions = response.data.moderation.applyToAllFunctions;
        this.moderationPayload.applyToAllLocations = response.data.moderation.applyToAllLocations;
        this.moderationPayload.applyToAllLevels = response.data.moderation.applyToAllLevels;

        this.applyToAllDesignations = response.data.moderation.applyToAllDesignations;
        this.applyToAllFunctions = response.data.moderation.applyToAllFunctions;
        this.applyToAllLevels = response.data.moderation.applyToAllLevels;
        this.applyToAllLocations = response.data.moderation.applyToAllLocations;


        if (response.data.moderation.moderators.length > 0) {
          this.selectedModerators.push({
            levelId: "Line Manager",
            weight: response.data.moderation.automaticModeratorWeight,
            moderator: "Line Manager",
          });

          this.moderatorSequence.push({
            levelId: "Line Manager",
            sequence: 0,
            label: "Line Manager",
            weight: response.data.moderation.automaticModeratorWeight,
          });

          response.data.moderation.moderators.forEach((moderator) => {
            this.selectedModerators.push({
              levelId: moderator.levelId,
              weight: moderator.weight,
              moderator: moderator.moderator,
            });
          });

          response.data.moderation.moderators.map((moderation, index) => {
            this.moderatorSequence.push({
              levelId:
                moderation.levelId === "Line Manager"
                  ? `Line Manager`
                  : moderation.levelId,
              sequence: moderation.sequence,
              label:
                moderation.levelId === "Line Manager"
                  ? `Line Manager`
                  : `Line Moderator ${index}`,
              weight: moderation.weight,
            });
            return {};
          });
        }

        if (this.templateSelected !== "") {
          this.openModerationBar();
        }
      });
    },

    async editModerationStructure(action) {
      try {
        await this.$handlePrivilege("performance", "editPerformanceModeration");
        if (this.$route.params.id === "create") {
          this.save(action);
        } else {
          this.edit(action);
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
  },

  created() {
    if (this.$route.params.id === "create") {
      this.setSequence();
    }
  },

  mounted() {
    this.getTemplates();
    this.getLocation();
    this.getJobLevels();
    this.getFunctions();
    if (this.$route.params.id !== "create") {
      this.displayModerationStructure(this.$route.params.id);
    }
    setInterval(() => {
      this.setModeratorsSequence();
    }, 1000);
  },
};
</script>
