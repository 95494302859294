<template>
  <div
    class="tooltip"
    :style="` width:${width}; left:${left}; right:${right}`"
  >
    <div
      class="tooltip__dialog flex flex-col"
    >
      <div
        class="tooltip__header text-white mb-1"
        v-if="hasHeaderSlot"
      >
        <slot name="header" />
      </div>
      <div class="tooltip__body text-white">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    width: {
      type: String,
      default: '116px',
    },
    left: {
      type: String,
      default: '0',
    },
    right: {
      type: String,
      default: '0',
    },
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
};
</script>

<style scoped>
.tooltip
{
    position: absolute;
    background-color: #333333;
    box-shadow: 0px 2px 4px rgba(45, 49, 66, 0.06), 0px 4px 6px rgba(45, 49, 66, 0.1);
    border-radius: 5px;
    padding: 12px 10px;
    color: #FFFFFF;
    min-height: 0px;
    margin-top: 0.4rem;
    z-index: 9999;
}

.tooltip::after
{
  content: " ";
  position: absolute;
  bottom: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltip__dialog
{
  width: auto;
  height: auto;
}

.tooltip__header
{
    min-height: 17px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.035em;
    border-bottom: 0.5px dashed #FFFFFF;
    margin: 4px 0px;
}

.tooltip__body
{
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.5px;
    margin: 4px 0px;
    font-weight: normal;
}
</style>
